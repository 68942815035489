<template>
  <MyAccountCheckAuthLoggedIn>
    <MaterialsGrid
      class="mevcut-container"
      :fetch-route="wishlistMaterialRoute"
      :wishlist="true"
    />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { url } = useDomainHost()
const { t } = useI18n()
const wishlistMaterialRoute = getAllRoutes().materialsRoutes.wishlistMaterialByServer
useHead({
  title: t('wishlist'),
  link: [useSeoCanonical(url + '/my-account/wishlist')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('wishlist'), url + '/my-account/wishlist')
    ])
  ]
})
</script>
